import React, { useRef, useEffect, useState } from "react";

function App() {
  const canvasRef = useRef(null);
  const [pixelSize, setPixelSize] = useState(5); 
  const gridSize = 1000; 
  const [canvasWidth, setCanvasWidth] = useState(1000); 
  const [canvasHeight, setCanvasHeight] = useState(1000); 
  const [isSelecting, setIsSelecting] = useState(false);
  const [selectionStart, setSelectionStart] = useState({ x: 0, y: 0 });
  const [selectionEnd, setSelectionEnd] = useState({ x: 0, y: 0 });


  useEffect(() => {
    const updateCanvasSize = () => {
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;


      const newCanvasSize = Math.min(windowWidth * 0.8, windowHeight * 0.8); 
      setCanvasWidth(newCanvasSize);
      setCanvasHeight(newCanvasSize);
      setPixelSize(newCanvasSize / gridSize);
    };

    updateCanvasSize();
    window.addEventListener("resize", updateCanvasSize);
    return () => window.removeEventListener("resize", updateCanvasSize);
  }, []);


  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    ctx.clearRect(0, 0, canvas.width, canvas.height);


    for (let y = 0; y < gridSize; y++) {
      for (let x = 0; x < gridSize; x++) {
        ctx.fillStyle = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
        ctx.fillRect(x * pixelSize, y * pixelSize, pixelSize, pixelSize);
      }
    }
  }, [pixelSize]);


  const handleMouseDown = (event) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const x = Math.floor((event.clientX - rect.left) / pixelSize);
    const y = Math.floor((event.clientY - rect.top) / pixelSize);
    setSelectionStart({ x, y });
    setSelectionEnd({ x, y });
    setIsSelecting(true);
  };


  const handleMouseMove = (event) => {
    if (!isSelecting) return;

    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const x = Math.floor((event.clientX - rect.left) / pixelSize);
    const y = Math.floor((event.clientY - rect.top) / pixelSize);
    setSelectionEnd({ x, y });
  };


  const handleMouseUp = () => {
    setIsSelecting(false);


    const startX = selectionStart.x;
    const startY = selectionStart.y;
    const endX = selectionEnd.x;
    const endY = selectionEnd.y;
    const pixelCount = Math.abs(endX - startX) * Math.abs(endY - startY);


    alert(`Seçili Piksel Sayısı: ${pixelCount}\nBaşlangıç: (${startX}, ${startY})\nBitiş: (${endX}, ${endY})`);
  };

  return (
    <div className="flex flex-col justify-between min-h-screen bg-white">
      <header className="w-full bg-black text-white py-8">
        <h1 className="text-4xl font-bold text-center">1 Milyon Liralık Sayfa</h1>
        <p className="text-center mt-4 text-lg">
          1 TL ile sonsuza kadar sürecek bir reklam verin!
        </p>
      </header>


      <div className="flex flex-col items-center py-8">
        <div className="relative  border border-gray-300 shadow-lg rounded-md">
          <canvas
            ref={canvasRef}
            width={canvasWidth}
            height={canvasHeight}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            className="border border-gray-400"
            style={{ cursor: "crosshair", maxWidth: "100%", height: "auto" }}
          />
          {isSelecting && (
            <div
              className="absolute border border-blue-600 bg-blue-100 opacity-50"
              style={{
                left: `${Math.min(selectionStart.x, selectionEnd.x) * pixelSize}px`,
                top: `${Math.min(selectionStart.y, selectionEnd.y) * pixelSize}px`,
                width: `${Math.abs(selectionEnd.x - selectionStart.x) * pixelSize}px`,
                height: `${Math.abs(selectionEnd.y - selectionStart.y) * pixelSize}px`,
              }}
            />
          )}
        </div>
      </div>

      <footer className="bg-black text-white text-center py-4">
        <p className="text-sm">© 2024 Pixel Bilişim Teknolojileri A.Ş | iletisim@1milyonliraliksayfa.com</p>
      </footer>
    </div>
  );
}

export default App;
